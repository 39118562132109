import React from 'react'
import { Link } from 'react-router-dom'
import styles from "../assets/css/Footer.module.css"
import iataLogo from "../assets/img/iata.png"
import tursabLogo from "../assets/img/tursab.png"

export default function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={`${styles.footer__container} container`}>
                <ul className={styles.footer__list}>
                    <li>
                        <a href="#about" className={styles.footer__link}>Hakkımızda</a>
                    </li>
                    <li>
                        <a href="#services" className={styles.footer__link}>Hizmetler</a>
                    </li>
                    <li>
                        <a href="#gallery" className={styles.footer__link}>Galeri</a>
                    </li>
                    <li>
                        <a href="#contact" className={styles.footer__link}>İletişim</a>
                    </li>
                </ul>

                <ul className={styles.footer__social}>
                    <a href="https://www.facebook.com/hafelturizm" target="_blank" className={styles.footer__social_link}>
                        <i className={"bx bxl-facebook"}></i>
                    </a>
                    <a href="https://twitter.com/hafelturizm" target="_blank" className={styles.footer__social_link}>
                        <i className={"bx bxl-twitter"}></i>
                    </a>
                    <a href="https://www.instagram.com/hafelturizm/" target="_blank" className={styles.footer__social_link}>
                        <i className={"bx bxl-instagram"}></i>
                    </a>
                </ul>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-around", paddingBottom: "2rem" }}>
                <img src={tursabLogo} width={180} />
                <img src={iataLogo} width={150} />
            </div>
        </footer>
    )
}
