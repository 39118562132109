import React from 'react'
import LendingPage from './screens/LendingPage'
import Footer from './components/Footer'
import ScrollUp from './product/ScrollUp'
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";

export default function Router() {

  return (
    <div>
      <Routes>
        <Route exact path='/' element={<LendingPage />} />
      </Routes>
      <ScrollUp />
    </div>
  )
}