import { React, useEffect, useState } from 'react'
import Home from './Home'
import About from './About'
import Services from './Services'
import Contact from './Contact'
import Gallery from './Gallery'

export default function Main() {
  return (
    <>
      <Home />
      <About />
      <Services />
      <Gallery />
      <Contact />
    </>
  )
}
