import React from 'react'
import RouterButton from '../product/RouterButton'
import styles from "../assets/css/About.module.css"
import logo from "../assets/img/logo.png"
import FadeAnimation from '../product/FadeAnimation'

export default function About() {
    return (
        <FadeAnimation>
            <section className={`${styles.about} ${styles.section} about section`} id="about" >
                <div>
                    <div className={`${styles.about__title} ${styles.section__title}  section__title`}>Hakkımızda</div>
                    <div className={`${styles.about__container} ${styles.container} ${styles.grid} about__container container grid`}>
                        <div className={styles.about__data}>
                            <h2 className={`${styles.section__subtitle}`}>Vizyonumuz</h2>
                            <p className={styles.about__description}>
                                Müşteri memnuniyetini en üst seviyede, en hesaplı fiyatlarla ve kalıcı olarak sağlamak, hizmet kalitemizden ödün vermeden, bizimle çalışmayı tercih eden firmalara, uzun vadeli ve güvenilir hizmeti vermektir.
                            </p>

                        </div>
                        <div className={styles.about__data}>
                            <h2 className={`${styles.section__subtitle}`}>Misyonumuz</h2>
                            <p className={styles.about__description}>
                                Müşterilerimizin beklentilerini ve gereksinimlerini tam zamanında karşılayıp, temel değerlerimiz doğrultusunda, iş ahlâkından ve kaliteden taviz vermeden ürün ve hizmetlerimizi müşterilerimize sunmaktır.
                            </p>

                        </div>
                    </div>
                </div>
            </section>
        </FadeAnimation>
    )
}
