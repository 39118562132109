import React from 'react'
import styles from "../assets/css/ScrollUp.module.css"

export default function ScrollUp() {

  function scrollUp(){
    const scrollUp = document.getElementById('scroll-up');
    if(this.scrollY >= 560) scrollUp.classList.add(styles.show_scroll); else                          scrollUp.classList.remove(styles.show_scroll)
}
window.addEventListener('scroll', scrollUp)

  return (
      <a href="#" className={styles.scrollup} id="scroll-up">
        <i className={`${styles.scrollup__icon} bx bx-chevron-up`}></i>
      </a>
  )
}
