import React, { useEffect, useRef } from "react";
import Typed from "typed.js";
import styles from "../assets/css/Home.module.css";
import RouterButton from "../product/RouterButton";
import homrBg from "../assets/img/homeBg.jpeg";
import LazyLoad from "react-lazy-load";
import FadeAnimation from "../product/FadeAnimation";

export default function Home() {

  return (
    <section className={`${styles.home} section`} id="home" style={{ height: "100vh" }}>
      <div>
        <LazyLoad>
          <img className={styles.videoBg} src={homrBg} />
        </LazyLoad>
      </div>
      <div className={`${styles.home__container} container grid`}>

        <div className={styles.home__content}>
          <FadeAnimation left duration={3000} delay={300}>
            <div
              className={styles.home__social}
            >
              <a
                href="https://api.whatsapp.com/send?phone=905435255905&text=Merhaba, daha fazla bilgi alabilir miyim?"
                target="_blank"
                className={styles.home__social_icon}
              >
                <i className={"bx bxl-whatsapp"}></i>
              </a>
              <a
                href="https://www.facebook.com/hafelturizm"
                target="_blank"
                className={styles.home__social_icon}
              >
                <i className={"bx bxl-facebook"}></i>
              </a>
              <a
                href="https://www.instagram.com/hafelturizm/"
                target="_blank"
                className={styles.home__social_icon}
              >
                <i className={"bx bxl-instagram"}></i>
              </a>
              <a
                href="https://twitter.com/hafelturizm"
                target="_blank"
                className={styles.home__social_icon}
              >
                <i className={"bx bxl-twitter"}></i>
              </a>
            </div>
          </FadeAnimation>
          <FadeAnimation top>
            <div className={styles.home__data} >
              <div className={styles.home__title__container}>
                <h1 className={styles.home__title}>
                  HAFEL TURİZM
                </h1>
              </div>
              <div className={styles.animated__text__container}>
                <h3 className={styles.home__subtitle}>
                  Kaliteli Bir Hizmet İçin

                </h3>
              </div>
              <p className={styles.home__description}>
                Hafel Turizm{"'"}e hoş geldiniz! Seyahat etmek, dünyayı keşfetmek ve yeni maceralara yelken açmak için buradayız. Sitemizde seyahat ipuçları, destinasyon rehberleri ve unutulmaz deneyimler bulacaksınız. Haydi, valizlerinizi hazırlayın ve bu seyahat tutkusuna katılın!
              </p>
              <div className={styles.home__bottom__buttons}>
                <div>
                <a href="#contact" className={"button button__flex"}>
                  <span>Hemen İletişime Geçin</span>
                  <i
                    className={`${styles.home__button__icon} bx bx-down-arrow-alt`}
                  ></i>
                </a>
              </div>
              <div>
                <a href="#" className={"button button__flex"}><i
                    className={`${styles.home__button__phone__icon} bx bx-phone`}
                  ></i>
                  <span>(0212) 424 22 33</span>
                  
                </a>
              </div>
              </div>
              
            </div>
          </FadeAnimation>
        </div>
      </div>
    </section>
  );
}
