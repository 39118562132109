import { React, useState, useEffect } from "react";
import BasicHeader from "../product/BasicHeader";
import styles from "../assets/css/BasicHeader.module.css"

export default function LendingHeader() {

  const [sections, setSections] = useState([]);

  useEffect(() => {
    setSections(document.querySelectorAll("section[id]"));
  }, []);

  const scrollActive = () => {
    const scrollY = window.pageYOffset;

    sections.forEach((current) => {
      const sectionHeight = current.offsetHeight,
        sectionTop = current.offsetTop - 58,
        sectionId = current.getAttribute("id"),
        sectionsClass = document.querySelector(
          ".nav__menu a[href*=" + sectionId + "]"
        );

      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        sectionsClass.classList.add(styles.active_link);
      } else {
        sectionsClass.classList.remove(styles.active_link);
      }
    });
  };
  window.addEventListener("scroll", scrollActive);

  return (
    <BasicHeader>
      <ul className={`${styles.nav__list} grid`}>
        <li className={styles.nav__item}>
          <a href="#home" className={`${styles.nav__link} active_link nav__link`}>
            <i className={`${styles.nav__icon} bx bx-home-alt`}></i> Anasayfa
          </a>
        </li>

        <li className={styles.nav__item}>
          <a href="#about" className={`${styles.nav__link} nav__link`}>
            <i className={`${styles.nav__icon} bx bx-user`}></i> Hakkımızda
          </a>
        </li>

        <li className={styles.nav__item}>
          <a href="#services" className={`${styles.nav__link} nav__link`}>
            <i className={`${styles.nav__icon} bx bx-compass`}></i> Hizmetler
          </a>
        </li>

        <li className={styles.nav__item}>
          <a href="#gallery" className={`${styles.nav__link} nav__link`}>
            <i className={`${styles.nav__icon} bx bx-images`}></i> Galeri
          </a>
        </li>

        <li className={styles.nav__item}>
          <a href="#contact" className={`${styles.nav__link} nav__link`}>
            <i className={`${styles.nav__icon} bx bx-message-square-detail`}></i> İletişim
          </a>
        </li>
      </ul>
    </BasicHeader>
  );
}
