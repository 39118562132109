import React from 'react'
import styles from "../assets/css/Services.module.css"

export default function BasicServiceCard(props) {
  return (
    <div className={styles.services__card}>
      <img alt='logo' src={props.imgSrc} className={styles.service__image} />
      <h3 className={styles.services__title}>{props.title}</h3>
      <p className={styles.services__detail}>
        {props.description}
      </p>
    </div>
  )
}
