import { React, useEffect, useState } from 'react'
import './App.css';
import "./main.js"

import { ToastContainer } from 'react-toastify';
import LendingPage from './screens/LendingPage';
import Router from './Router';

function App() {

  return (
    <>
      <ToastContainer />
      <Router />
    </>

  );
}

export default App;
