import { useFormik } from 'formik';
import { React, useState, useEffect } from 'react'
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from "../assets/css/Contact.module.css"
import FadeAnimation from '../product/FadeAnimation';

export default function Contact() {

    useEffect(() => {
        emailjs.init("UZ-eKuYAITBeAmIdC")
    }, []);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: ""
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(30, '30 karakterden fazla olamaz')
                .required('Zorunlu Alan'),
            email: Yup.string()
                .email('Geçersiz E-Posta Adresi')
                .required('Zorunlu Alan'),
            message: Yup.string()
                .required('Zorunlu Alan'),
        }),
        onSubmit: values => {
            var obje = Object.assign({}, values)
            const id = toast.loading("Mesaj Gönderiliyor...",
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    style: { fontSize: "13.5px" },
                }
            )
            emailjs.send("service_3fe74vy", "template_d7vrvlh", obje).then(
                res => {
                    formik.resetForm()
                    toast.update(id, {
                        type: "success",
                        render: 'Mesajınız Başarıyla Gönderildi',
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        style: { fontSize: "13.5px" },
                        isLoading: false
                    }
                    )

                },
                er => {
                    console.log(er)
                    toast.update(id, {
                        render: er.text,
                        type: "error",
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        style: { fontSize: "13.5px" },
                        isLoading: false
                    }
                    )
                }
            )
        }
    })


    return (

        <section className={`${styles.contact} section`} id="contact">
            <div>
                <h2 className={`${styles.contacts__title} section__title`}>Hemen İletİŞİme GEÇİN</h2>

                <div className={`${styles.contact__container} container grid`}>
                    <FadeAnimation >
                        <div className={styles.contact__content}>
                            <div className={styles.contact__info}>
                                <div className={styles.contact__card}>
                                    <i className={`${styles.contact__card_icon} bx bxl-whatsapp`}></i>
                                    <h3 className={styles.contact__card_title}>
                                        Whatsapp
                                    </h3>
                                    <span className={styles.contact__card_data}>+905435255905</span>
                                    <a
                                        href="https://api.whatsapp.com/send?phone=905435255905&text=Merhaba, daha fazla bilgi alabilir miyim?"
                                        target="_blank" className={styles.contact__button}>
                                        <div className={styles.contact__write_us}>
                                            <span>İletişime Geç </span>
                                            <i className={`${styles.contact__button_icon} bx bx-right-arrow-alt`}></i>
                                        </div>
                                    </a>
                                </div>
                                <div className={styles.contact__card}>
                                    <i className={`${styles.contact__card_icon} bx bxl-facebook`}></i>
                                    <h3 className={styles.contact__card_title}>Facebook</h3>
                                    <span className={styles.contact__card_data}>facebook.com/hafelturizm</span>

                                    <a href="https://www.facebook.com/hafelturizm"
                                        target="_blank" className={styles.contact__button}>
                                        <div className={styles.contact__write_us}>
                                            <span>İletişime Geç </span>
                                            <i className={`${styles.contact__button_icon} bx bx-right-arrow-alt`}></i>
                                        </div>
                                    </a>
                                </div>
                                <div className={styles.contact__card}>
                                    <i className={`${styles.contact__card_icon} bx bxl-twitter`}></i>
                                    <h3 className={styles.contact__card_title}>Twitter</h3>
                                    <span className={styles.contact__card_data}>twitter.com/hafelturizm</span>
                                    <a href="https://twitter.com/hafelturizm" target="_blank" className={styles.contact__button}>
                                        <div className={styles.contact__write_us}>
                                            <span>İletişime Geç</span>
                                            <i className={`${styles.contact__button_icon} bx bx-right-arrow-alt`}></i>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </FadeAnimation>

                    <FadeAnimation>
                        <div className={styles.contact__content}>
                            <h3 className={styles.s}>Yazmaya Başla !</h3>
                            <form action="" className={styles.contact__form} onSubmit={formik.handleSubmit}>
                                <div className={styles.contact__form_div}>
                                    <label className={styles.contact__form_tag}>
                                        <span>İsim ve Soyisminiz</span>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="İsminizi Yazın"
                                        className={styles.contact__form_input}
                                        id='name'
                                        name='name'
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className={styles.contact__form_error}>{formik.errors.name}</div>
                                    ) : null}
                                </div>
                                <div className={styles.contact__form_div}>
                                    <label htmlFor="email" className={styles.contact__form_tag}>Mail</label>
                                    <input
                                        type="email"
                                        placeholder="Mail Adresinizi Yazın"
                                        className={styles.contact__form_input}
                                        id='email'
                                        name='email'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className={styles.contact__form_error}>{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <div className={`${styles.contact__form_area} ${styles.contact__form_div}`}>
                                    <label className={styles.contact__form_tag}>
                                        <span>Mesaj</span> </label>
                                    <textarea
                                        name="message"
                                        placeholder="Nasıl Yardımcı Olabiliriz ?"
                                        cols="30"
                                        rows="10"
                                        id='message'
                                        className={styles.contact__form_input}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.message}
                                    />
                                    {formik.touched.message && formik.errors.message ? (
                                        <div className={`${styles.contact__form_error} ${styles.contact__form_error_textarea}`}>
                                            {formik.errors.message}
                                        </div>
                                    ) : null}
                                </div>
                                <span>
                                    <button type='submit' className={"button"} id='contact-button'>
                                        <div style={{ display: "flex" }}>
                                            <span>Mesajı Gönder</span>
                                            <i className={"bx bx-send button__icon button__flex"}></i>
                                        </div>
                                    </button>
                                </span>
                            </form>
                        </div>
                    </FadeAnimation>
                </div>
            </div>
        </section>

    )
}
