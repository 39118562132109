import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import ScrollToTop from './product/ScrollToTop';
import Footer from './components/Footer';
import ScrollUp from './product/ScrollUp';
import { BrowserRouter } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <ScrollToTop />
        <App />
    </BrowserRouter>
);

