import React, { useEffect, useState } from 'react'
import RouterButton from '../product/RouterButton';
import BasicServiceCard from '../product/BasicServiceCard';
import styles from "../assets/css/Services.module.css"
import ServiceList from '../product/Objects/Services';
import { Navigate, Router, createSearchParams, useNavigate } from 'react-router-dom';
import FadeAnimation from '../product/FadeAnimation';

export default function Services() {

    const navigate = useNavigate();

    return (
        <FadeAnimation>
            <section className={`${styles.services} section ${styles.services__section}`} id="services">

                <div className={styles.services__content}>
                    <h2 className={"section__title"}>HİZMETLERİMİZ</h2>
                    <div className={`${styles.services__container} container grid`}>
                        {
                            ServiceList.map((s, i) => (
                                <BasicServiceCard
                                    key={i}
                                    imgSrc={s.image}
                                    title={s.title}
                                    description={s.description}
                                />
                            ))
                        }
                    </div>
                </div>
            </section>
        </FadeAnimation>
    )
}
