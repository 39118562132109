import React from 'react'
import LendingHeader from '../components/LendingHeader'
import Main from '../components/Main'
import Footer from '../components/Footer'
import ScrollUp from '../product/ScrollUp'
import { Route } from 'react-router-dom'


export default function LendingPage() {
  return (
    <>
      <LendingHeader />
      <Main />
      <Footer />
    </>
  )
}
