import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/img/logo.png'
import styles from "../assets/css/BasicHeader.module.css"

export default function BasicHeader({ children }) {

  const [navMenu, setNavMenu] = useState();
  const [navToggle, setNavToggle] = useState();
  const [navClose, setNavClose] = useState();
  const [navState, setNavState] = useState(false);
  const [navLink, setNavLink] = useState([]);

  useEffect(() => {
    setNavMenu(document.getElementById("nav-menu"));
    setNavToggle(document.getElementById("nav-toggle"));
    setNavClose(document.getElementById("nav-close"));
    setNavLink(document.querySelectorAll(".nav__link"));
  }, []);

  useEffect(() => {
    if (navState) {
      navClose.classList.add(styles.show_button)
    }
  }, [navState]);

  if (navLink) {
    navLink.forEach((n) =>
      n.addEventListener("click", () => {
        navMenu.classList.remove(styles.show_menu);
        setNavState(false);
      })
    );
  }

  if (navToggle) {
    navToggle.addEventListener("click", () => {
      navMenu.classList.add(styles.show_menu);
      setNavState(true);
    });
  }

  if (navClose) {
    navClose.addEventListener("click", () => {
      navMenu.classList.remove(styles.show_menu);
      setNavState(false);
    });
  }


  function linkAction() {
    navMenu.classList.remove(styles.show_menu)
  }
  navLink.forEach(n => n.addEventListener('click', linkAction))


  return (
    <header className={`${styles.header} header`} id="header">
      <nav className={`${styles.nav} container`}>
        <Link style={{ display: "contents" }} to="/" onClick={() => { window.scrollTo(0, 0); }}>
          <img id="logo" className={`${styles.navLogo}`} src={logo} alt="logo" srcSet="" height="auto" />
        </Link>

        <div className={`${styles.nav__menu} nav__menu`} id="nav-menu">
          {children}
          <i className={`${styles.nav__close} bx bx-x`} id="nav-close"></i>
        </div>
        <div className={styles.nav__btns}>
          <div className={`${styles.nav__toggle} show_button`} id="nav-toggle">
            <i className={"bx bx-menu"}></i>
          </div>
        </div>
      </nav>
    </header>
  )
}
