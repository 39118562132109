import arac from "../../assets/img/arac.jpg"
import otel from "../../assets/img/otel.jpg"
import fuar from "../../assets/img/fuar.jpg"
import transfer from "../../assets/img/transfer.jpg"
import ucak from "../../assets/img/ucak.jpg"
import vize from "../../assets/img/vize.jpg"

const Services = [
    {
        id: 0,
        image: otel,
        title: "En Uygun Oteller",
        description: "Dünya'dan ve Türkiye'den iyi otel ve tesisler ile özel anlaşmalı indirimler ve kampanyalarımızı ekibimiz ile sizler için tarayıp ihtiyaçlarınıza uygun alternatiferi bir araya getiriyoruz. Rezervasyon işlemlerinizi gerçekleştirip teyitlerinizi iletiyoruz. Konaklama bitinceye kadar var olan tüm soru ve sorunlarınız ile son aşamaya kadar ilgileniyoruz."
    },
    {
        id: 1,
        title: "Uçak Bileti",
        image: ucak,
        description: "IATA belgesine sahip acentemiz, sizlere Dünyanın her noktası için farklı havayolları ile en uygun uçak bileti imkanını sunuyor. Misafirlerimizin uçağa biniş anına kadar tüm soru ve sorunlarınız için uzman ekibimiz 7 gün 24 saat kesintisiz hizmet vermektedir."
    },
    {
        id: 2,
        title: "Araç Kiralama",
        image: arac,
        description: "Gerek ticari gerekse binek grupta talep ettiğiniz her marka ve model aracımız ile belirlediğiniz bütçe doğrultusunda sizlere alternatifler sunuyoruz. Enterprise, Garenta Ve Rent Go gibi kurumsal firmalar ile sizlere güvenilir bir hizmet veriyoruz."
    },
    {
        id: 3,
        image: fuar,
        title: "Fuar Hizmetleri",
        description: "Şirketinizin kurumsal seyahatleri ve fuar ziyareti planlamaları konusunda uzmanlaşmış ekibimiz ile titizlik ve özenle çalışarak size özel seyahat paketleri sunarız."
    },
    {
        id: 4,
        image: vize,
        title: "Vize Danışmanlığı",
        description: "Konusunda uzman vize danışmanlarımız ile seyahat edeceğiniz ülkenin vize başvurusu için gerekli detayları tarafınıza zamanında ve eksiksiz biçimde iletiyoruz. Evrak hazırlığı, başvuru yapım ve takibi, iletim gibi süreçlerin tamamını sizler adına yürütüyoruz."
    },
    {
        id: 5,
        image: transfer,
        title: "Transfer Hizmeti",
        description: "Bütçeniz ve ihtiyaçlarınız doğrultusunda seçeceğiniz araçlarımızla dilerseniz VIP hizmet alın dilerseniz ekonomik paketlerimizden faydalanın"
    }
]

export default Services;