import React from 'react'
import Gallery1 from '../assets/img/gallery1.jpg'
import Gallery2 from '../assets/img/gallery2.jpg'
import Gallery3 from '../assets/img/gallery3.jpg'
import Gallery4 from '../assets/img/gallery4.jpg'
import Gallery5 from '../assets/img/gallery5.jpg'
import Gallery6 from '../assets/img/gallery6.jpg'
import Gallery7 from '../assets/img/gallery7.jpg'
import Gallery8 from '../assets/img/gallery8.jpg'
import Gallery9 from '../assets/img/gallery9.jpg'
import Gallery10 from '../assets/img/gallery10.jpg'
import Gallery11 from '../assets/img/gallery11.jpg'
import styles from "../assets/css/Gallery.module.css"
import FadeAnimation from '../product/FadeAnimation'

export default function Gallery() {
    return (
        <FadeAnimation>
            <section className={`${styles.gallery} section ${styles.gallery__section}`} id="gallery">
                <div className={styles.gallery__content}>
                    <h2 className={"section__title"}>GALERİ</h2>
                    <div className={`${styles.gallery__container}`}>
                        <div className={styles.gallery__columns}>
                            <img src={Gallery1} className={styles.image} />
                            <img src={Gallery2} className={styles.image} />
                            <img src={Gallery4} className={styles.image} />
                            <img src={Gallery3} className={styles.image} />
                            <img src={Gallery5} className={styles.image} />
                            <img src={Gallery9} className={styles.image} />
                            <img src={Gallery6} className={styles.image} />
                            <img src={Gallery7} className={styles.image} />
                            <img src={Gallery8} className={styles.image} />
                            <img src={Gallery10} className={styles.image} />
                            <img src={Gallery11} className={styles.image} />
                        </div>
                    </div>
                </div>
            </section>
        </FadeAnimation>
    )
}
